
@import '~@angular/material/theming';

@include mat-core();

@mixin style-theme($theme) {
  $p: map-get($theme, primary);
  $a: map-get($theme, accent);
  $w: map-get($theme, warn);
  $primary: mat-color($p);
  $accent: mat-color($a);
  $warn: mat-color($w);
  $primary-contrast: mat-color($p, default-contrast);
  $accent-contrast: mat-color($a, default-contrast);
  $warn-contrast: mat-color($w, default-contrast);

  .color.primary {
    color: $primary;
  }

  .color.accent {
    color: $accent;
  }

  .color.warn {
    color: $warn;
  }

  .background.primary {
    background-color: $primary;
    color: $primary-contrast;
  }

  .background.accent {
    background-color: $accent;
    color: $accent-contrast;
  }

  .background.warn {
    background-color: $warn;
    color: $warn-contrast;
  }

  code.snippet,
  a.link {
    color: $primary;
  }

  code.snippet.alt,
  a.link:visited {
    color: $accent;
  }
}

html, body, app, .app-panel {
    overflow: hidden;
    margin: 0;
    height: 100%;
}

.app-body {
    height: 100%;
    overflow: auto;
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography h6,
.mat-typography p {
  margin: 8px;
}

div[class*="light-"] mat-paginator.mat-paginator {
    background-color: #fafafa;
}

div[class*="dark-"] mat-paginator.mat-paginator {
    background-color: #303030;
}

div[class*="dark-"] text {
    fill: $light-primary-text;
}
$green: mat-palette($mat-green, A400);
$blue: mat-palette($mat-blue, A400);
$red: mat-palette($mat-red);
$indigo: mat-palette($mat-indigo, A400);
$deep-orange: mat-palette($mat-deep-orange, A200);
$red-warn: mat-palette($mat-red, A100);

/*-- Global Styling --*/
$default-theme: mat-light-theme($green, $blue);
@include style-theme($default-theme);
@include angular-material-theme($default-theme);

/*-- Theming --*/
.light-green {
    $default-theme: mat-light-theme($green, $blue);
    @include style-theme($default-theme);
    @include angular-material-theme($default-theme);
}

.dark-green {
    $default-theme: mat-dark-theme($green, $blue);
    @include style-theme($default-theme);
    @include angular-material-theme($default-theme);
}

.light-red {
    $default-theme: mat-light-theme($red, $blue, $red-warn);
    @include style-theme($default-theme);
    @include angular-material-theme($default-theme);
}

.dark-red {
    $default-theme: mat-dark-theme($red, $blue, $red-warn);
    @include style-theme($default-theme);
    @include angular-material-theme($default-theme);
}

.light-blue {
    $default-theme: mat-light-theme($blue, $green);
    @include style-theme($default-theme);
    @include angular-material-theme($default-theme);
}

.dark-blue {
    $default-theme: mat-dark-theme($blue, $green);
    @include style-theme($default-theme);
    @include angular-material-theme($default-theme);
}

.light-indigo {
  $default-theme: mat-light-theme($indigo, $deep-orange);
  @include style-theme($default-theme);
  @include angular-material-theme($default-theme);
}

.dark-indigo {
  $default-theme: mat-dark-theme($indigo, $deep-orange);
  @include style-theme($default-theme);
  @include angular-material-theme($default-theme);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.content{
    margin: 0 auto;
    max-width: 1000px;
}
